.tms-primary-btn {
  border-radius: 5px !important;
  background: #e65c4f !important;
  color: #fff !important;
  font-family: Montserrat;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #e65c4f !important;
  width: 100%;
  padding: 8px 12px !important;
  transition: 0.3s ease-in !important;
}
.tms-primary-btn:hover {
  background: #fff;
  color: #1d1f2e;
}
