.project-ctr-activity-main{
    .header{
        display: flex;
        justify-content: space-between;
    }
    .header-content{
        font-weight: 700;
        font-size: 24px;
    }
    .trainer-type-dropdown-main {
        .trainer-type-dropdown {
          background: #E65C4F !important;
          padding: 10px 16px !important;
          font-family: "Montserrat" !important;
          cursor: pointer;
          border: none !important;
          display: flex !important;
          border-radius: 8px !important;
          align-items: center;
          justify-content: center;
          color: var(--white, #fff);
          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 160% !important;
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          border-radius: 8px;
          background-color: var(--background-2, #f8f8fa);
          border: 1px solid #f8f8fa;
          color: var(--text-color, #4d5d6b);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 12px 0px;
        }
      }
      .project-ctr-activity-table-main{
        background-color: white;
        padding:20px;
      }
}