.loader-main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader-button {
    width: fit-content;
    margin: 0 auto;
    padding: 0;
}