.addidcmatrix-main{
  
    .addidcmatrix-discpline{
        background-color: white;
        width:60%;
        padding: 20px;
        border-radius: 20px;

    }
    .workflow-head{
        font-size:16px;
        font-weight: 700;
    }

}