.reset-password-main{
   
    display: flex;
    justify-content: center;
    .reset-password-box{
        background-color: white;
        border-radius: 10px;
        padding: 30px;
        width:400px;
    }
}