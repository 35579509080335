.form-label,
.custom-control-label {
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000;
}
.input-element {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #000;
  outline: none;
  padding: 11px 16px;
  color: var(--text-color, #4d5d6b);
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-element:focus {
  outline: none;
}
.input-element:focus-visible {
  outline: none;
}

.input-group-main {
  width: 100%;
  .input-element {
    width: 90%;
    margin-left: 0px;
    margin-right: 1px;
  }
  .start-text {
    border: none;
    background: rgba(14, 64, 102, 0.05);
    border-radius: 16px 0 0 16px;
  }
  .end-text {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    background: var(--bg-2, #f8f8fa);
    border-radius: 0 8px 8px 0;
    cursor: pointer;
  }
}
