//Icon Button
.btn-with-icon {
  border-radius: 8px;
  background: #E65C4F !important;
  padding: 10px 16px !important;
  font-family: "Montserrat" !important;
  cursor: pointer;
  border: none !important;
  display: flex !important;
  border-radius: 8px !important;
  align-items: center;
  justify-content: center;
  color: var(--white, #fff);
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;

  .btn-with-icon-title {
    margin: 0 0 0 5px !important;
  }
  .btn-icon,
  img {
    font-size: 30px;
  }
}
