.sidebar-main {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .himsidebar-logo {
    display: flex;
    align-items: center;
    gap: 8px;

    .himsidebar-logo-main {
      width: auto;
      height: 130px;

      img {
        height: 100%;
      }
    }

    .himsidebar-content-main {
      font-family: "Poppins";
      font-style: normal;

      .portal-content {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #ffffff;
      }

      .tagline-content {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #ffffff;
      }
    }
  }

  .logo-text {
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    color: #ffffff;
  }

  .nk-sidebar,
  .nk-sidebar-head {
    width: 275px;
    transform: unset;
    min-width: 100%;
  }

  .nk-sidebar-head {
    overflow: visible;
    padding: 0;
    margin: 0;
    display: flex;
    background-color: #131313;
    height: fit-content;

    .nk-sidebar-brand {
      margin: 0 auto;
      background-color: #131313;
      width: auto;
      z-index: 100;
      padding-block: 1.5em;

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
  .nk-sidebar {
    background: #131313;
    height: 100%;
    min-height: 100vh;
    border-right: 1px solid #e5e9f2;
    box-shadow: 0 1px 3px 0 rgba(54, 74, 99, 0.05);
    transition: transform 0.45s ease, width 0.45s ease,
      -webkit-transform 0.45s ease;

    .nk-sidebar-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 65px);
    }

    .nk-sidebar-content .nk-sidebar-menu[data-simplebar] {
      height: 100%;
    }

    .sidebar-addicon-btn-main {
      border-radius: 8px;
      background: var(--1-st-color, #e65c4f);
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
      padding: 5px 16px;
      margin: 40px 25px;
      .sidebarbtn-with-icon-title {
        color: var(--white-color, #fff);
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
    }

    .nk-sidebar-menu {
      overflow-y: auto;

      .simplebar-scrollbar::before {
        background-color: #3963ad;
        opacity: 1 !important;
      }

      .nk-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        .nk-menu-item.has-sub {
          position: relative;
        }
        .nk-menu-item.has-sub:hover > .nk-menu-toggle:after {
          border-color: #fff;
        }

        .nk-menu-item {
          font-size: 14px;
          padding: 4px 12px;

          .nk-menu-link {
            display: flex;
            gap: 1em;
            vertical-align: middle;
            position: relative;
            transition: color 0.3s, background-color 0.3s;
            padding: 0.8rem 1.3333333333px 0.8rem 12px;
            letter-spacing: 0.01em;
            text-transform: none;
            text-decoration: none;
            color: var(--white, #fff);
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;

            &:hover,
            &.active {
              color: #fff;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              background: #e65c4f;
              border-radius: 8px;

              .nk-menu-icon {
                em {
                  color: white;
                }

                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
            }

            .nk-menu-icon {
              width: 1.25rem;
              height: 1.25rem;

              img {
                object-fit: contain;
                width: 100%;
              }
            }
          }

          &.has-sub {
            &:hover,
            &.active {
              span.nk-menu-link {
                color: #fff;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                background: #e65c4f;
                border-radius: 8px;

                .nk-menu-icon {
                  em {
                    color: white;
                  }

                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }
            }

            ul.nk-menu-sub {
              padding-bottom: 0.25rem;
              padding-top: 0.25rem;

              li.nk-menu-item {
                padding: 0 0 0 1em;

                a.nk-menu-link {
                  border-left: 1px solid darkgray;
                  border-radius: unset;
                  padding: 0.7em 0 0.7em 1em;
                  letter-spacing: normal;
                  text-transform: none;
                  color: var(--white, #fff);
                  font-family: Montserrat;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 160%;

                  &.active {
                    background-color: transparent;
                    border-left: 1px solid #e65c4f;
                    span {
                      color: #e65c4f;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .sidebar-option-menu-main {
        padding: 0px 0px 40px;
      }

      .nk-menu li .span-toggle {
        vertical-align: middle;
        display: flex;
        position: relative;
        align-items: center;
        transition: color 0.3s, background-color 0.3s;
        cursor: pointer;
      }

      .nk-menu-toggle:after {
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        font-family: "Nioicon";
        top: 50%;
        content: "";
        right: 1.25rem;
        font-size: 1rem;
        color: #8094ae;
        transition: transform 0.3s, color 0.1s, opacity 0.3s ease 0.3s;
        width: 10px;
        height: 10px;
        border-width: 0px 1px 1px 0px;
        border-color: white;
        border-style: solid;
        border-radius: 1px;
      }

      .has-sub.active > .nk-menu-toggle:after {
        transform: translateY(-50%) rotate(225deg);
        color: unset;
        top: 60%;
        border-color: #fff;
      }

      .has-sub.menu-sub-collapse > .nk-menu-toggle:after {
        transform: translateY(-50%) rotate(45deg) !important;
        top: 50% !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .sidebar-main {
    .cross-mark-main {
      position: absolute;
      right: 1em;
      top: 1em;
      cursor: pointer;
      display: unset;
    }
  }
}
