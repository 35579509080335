.idcmatrix-details-main {
    
    .details-workflow-main {
        padding: 20px 45px;

        .project-details-page-main {
            padding: 20px;
            background: #ffffff;

            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .project-details-tab-main {
                display: flex;
                gap: 50px;
                align-items: center;

                .add-project-tabs {
                    padding: 14px;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0em;
                    color: #131313;
                    cursor: pointer;

                    &.active {
                        color: #5046e5;
                        border-bottom: 2px solid #5046e5;
                    }
                }
            }

            .product-details-data-main {
                padding: 15px;
                font-size: 16px;
                
            }
        }

        .pre-heading {
            padding: 10px 20px;
            background: #ffffff;
            border: 1px solid #4d5d6b;

            border-radius: 8px;
            border-radius: 8px;
        }

        .add-project-card-header-main {
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0em;
            color: #131313;
        }

        .add-project-card-para-main {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0em;
            color: #131313;
            text-align: end;
        }
    }

    p {
        font-family: Montserrat;
        margin: 0 !important;
        font-size: 14px;
        color: #4d5d6b;
    }

    .back-btn-function {
        cursor: pointer;
        width: 40px !important;
    }
}