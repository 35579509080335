.modal-header {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #131313;
    img {
        cursor: pointer;
    }
}

.success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    font-weight: 600;
    font-size: 1rem;
    p {
        margin: 0;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 2rem;
        line-height: 39px;
        display: flex;
        align-items: center;
        text-align: center;
        color: black;
    }
    div {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
}