.pagination {
  gap: 0.5em;
  .page-link {
    border-radius: 8px;
    border: none;
  }
  .page-item.active .page-link {
    background: #E65C4F;
    color: #fff;
  }
}
.pagination-goto {
  text-transform: unset;
  .right-next {
    cursor: pointer;
    border-radius: 50px !important;
    background: var(--2nd-color, #E65C4F);
    color: #fff !important;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px !important;
    &:hover {
      background: grey;
      border: 1px solid #E65C4F;
    }
  }
}
