.dropdown-main {
  border-radius: 8px;
  .dropdown-menu,
  .dropdown-menu:hover {
    top: 5px !important;
    padding: 5px 0px;
    border-radius: 8px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: #f8f8fa;
  }
  .dropdown-content-main,
  .dropdown-item {
    color: var(--text, #4d5d6b);
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 16px;
    background: #f8f8fa;
    border: none;
    &:active,
    &:focus,
    &:focus-visible {
      border: none;
    }
  }
  .dropdown-content-main {
    padding: 12px;
    border: 1px solid #f8f8fa;
    &:hover {
      background: #f8f8fa;
    }
  }
}
