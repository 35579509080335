.repository-main {
    .search-input-main {
      background: #f8f8fa !important;
      border: 1px solid #f8f8fa !important;
      font-size: 12px !important;
      color: var(--black, #131313) !important;
      font-family: "Montserrat" !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
  
      &::placeholder {
        color: var(--black, #131313) !important;
        font-family: "Montserrat" !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
      }
  
      &:focus {
        box-shadow: none !important;
      }
    }
    // .schedule-view-icon-btn {
    //   border-radius: 8px;
    //   background: #f8f8fa;
    //   padding: 7px 16px;
    //   border: 1px solid #f8f8fa;
    //   .schedule-view-icon-main {
    //     width: 24px;
    //     height: 24px;
    //     img {
    //       height: 100%;
    //     }
    //   }
    //   &:hover {
    //     background: #1d1f2e;
    //     border: 1px solid #1d1f2e;
    //     .schedule-view-icon-main {
    //       img {
    //         filter: brightness(0) invert(1);
    //       }
    //     }
    //   }
    // }
    // .schedule-view-active-main {
    //   background: #1d1f2e;
    //   border: 1px solid #1d1f2e;
    //   .schedule-view-icon-main {
    //     img {
    //       filter: brightness(0) invert(1);
    //     }
    //   }
    // }
    .option-icon-btn-main {
      border-radius: 8px;
      background: #e65c4f;
      padding: 5px 16px;
      cursor: pointer;
      .option-btn-content-main {
        color: #fff;
        font-family: "Inter";
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
    .select-all-checkboxes-main {
      color: #131313;
      font-family: "Montserrat";
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .document-grid-content-card-main {
      border-radius: 8px;
      border: 0.5px solid #4d5d6b;
      background: #fff;
      padding: 8px;
      margin-top: 20px;
  
      .doc-content-details-main {
        margin-top: 10px;
        .doc-content-details-header {
          color: #131313;
          font-family: "Inter";
          font-size: 0.8rem;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
        }
      }
      .doctype-image-main {
        border-radius: 8px;
        background: #fafafa;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
      }
      .doc-content-btn-main {
        padding: 5px 10px;
        border-radius: 8px;
        background: #fafafa;
        cursor: pointer;
      }
      .doc-content-details-para {
        color: #4d5d6b;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
      .doctype-grid-header-main {
        color: #131313;
        font-family: "Montserrat";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
    }
    .margin-auto {
      margin: 40px auto;
    }
    .folder-view-project-name {
      color: #000;
      font-family: "Montserrat";
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.48px;
      overflow-wrap: break-word; 
      word-wrap: break-word; 
    }
    .folder-view-main {
      height: 120px;
      width: 120px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../../../assets/images/icons/folders-bg.svg");
  
      .folder-view-content-main {
        padding: 50px 10px 0px;
  
        .folder-view-content-header {
          color: #fff;
          font-family: "Montserrat";
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.42px;
        }
        .folder-view-content-para {
          color: #fff;
          font-family: "Inter";
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.72px;
        }
      }
    }
    .doc-filter-card-main {
      border-radius: 8px;
      background: var(--white, #fff);
      padding: 16px;
    }
    .doc-filter-grey-card-main {
      background: var(--background-color, #fafafa);
      padding: 16px 8px;
      margin-top: 15px;
      .doc-accordion-main {
        background: none;
        border: none;
        border-radius: 0;
  
        .accordion-button:not(.collapsed),
        .accordion-button.collapsed {
          background: rgba(230, 92, 79, 0.15);
          box-sizing: none;
          padding: 10px;
          border-radius: 0px;
          color: #131313;
          font-family: "Montserrat";
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          box-shadow: none;
        }
        .accordion-button.collapsed {
          background: none;
        }
        .accordion-body {
          padding: 10px;
          color: #131313;
          font-family: "Inter";
          font-size: 0.7rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .document-custom-search-main {
      border-radius: 8px;
      border: 0.5px solid var(--text-color, #4d5d6b);
      background: var(--white, #fff);
      padding: 4px 8px;
      display: flex;
      width: 100%;
      .doc-custom-select {
        width: 25%;
        .css-t3ipsp-control,
        .css-1nmdiq5-menu {
          border: 0.5px solid #4d5d6b;
          color: #4d5d6b !important;
          font-size: 0.7rem;
          letter-spacing: 0.12px;
          width: 100%;
          border-radius: 8px !important;
          background: #fafafa;
          outline: none;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .doc-custom-input {
        width: 55%;
      }
      .option-icon-btn-main {
        width: 20%;
      }
      .input-element {
        border: none;
        background: none;
        color: #4d5d6b;
        font-family: "Montserrat";
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  
    .doc-custom-suggestions-main {
      display: flex;
      gap: 25px;
      margin: 25px 0px;
      align-items: center;
      justify-content: center;
  
      .custom-suggestions-header {
        color: #131313;
        font-family: "Montserrat";
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .custom-suggestions-purple-card {
        border-radius: 50px;
        background: #f5f4ff;
        padding: 8px 15px;
        color: #131313;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 5px;
        .card-remove-icon-main {
          margin-left: 7px;
          cursor: pointer;
        }
      }
    }
  
    .export-modal-header-main {
      color: #131313;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .content {
      height: 100%;
      .custom-column-5 {
        width: 5%;
      }
    
    }
  }
  