.add-project-main {
  padding: 0px 25px;
  .add-project-card-main {
    background: #ffffff;
    padding: 24px;
    .add-project-card-header-main {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #131313;
    }
    .add-project-card-para-main {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0em;
      color: #131313;
    }
    .add-project-tab-main {
      display: flex;
      gap: 50px;
      align-items: center;
      .add-project-tabs {
        padding: 14px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #131313;
        cursor: pointer;
        &.active {
          color: #5046e5;
          border-bottom: 2px solid #5046e5;
        }
      }
    }
  }
}
.success-modal-content-main {
  .success-modal-content-para-main {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    color: #131313;
  }
  .primary-theme-icon-btn {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #ffffff;
    border: 1px solid #5046e5;
    background: #5046e5;
  }
  .primary-bordered-icon-btn {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #4d5d6b;
    background: #fff;
    border: 1px solid #4d5d6b;
  }
}

.delivery-hierarchy{
  .rct-node-icon{
    color: #e65c4f;
  }
}
