@import url("../../../assets/css/dropdown.scss");
@import url("../../../assets/css/modal.scss");
@import url("../../../assets/css/table.scss");

.Document-list-main {
  .admin-popular-main {
    .search-input-main {
      background: #f8f8fa !important;
      border: 1px solid #f8f8fa !important;
      font-size: 12px !important;
      color: var(--black, #131313) !important;
      font-family: "Montserrat" !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;

      &::placeholder {
        color: var(--black, #131313) !important;
        font-family: "Montserrat" !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
    a {
        text-decoration: none;
        color: black;
      }
  
      .content-header-main {
        color: var(--black, #131313);
        font-family: "Montserrat";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        margin-bottom: 0px;
      }
  
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
      }
  
      .action-btn-main {
        border-radius: 4px;
        background: #9747ff;
        padding: 8px 16px;
        color: #fff;
        text-align: center;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .product-list-header-flex {
        .custom-status-select {
          border-radius: 8px;
          background-color: var(--background-2, #f8f8fa);
          border: 1px solid #f8f8fa;
          color: var(--text-color, #4d5d6b);
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 12px;
          &:focus {
            box-shadow: none;
          }
        }
      }
}
}