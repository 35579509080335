.pendingReview-main{
   .heading{
      display: flex;
      justify-content: space-between;
   }
   .pending-table-main{
    background-color: white;
    margin-top:20px;
    padding: 20px;
   }
}