.new-repository-main{
    .folder-view-project-name {
        color: #000;
        font-family: "Montserrat";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        overflow-wrap: break-word; 
        word-wrap: break-word; 
      }
      .folder-view-main {
        height: 100px;
        width: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../../../assets/images/icons/folders-bg.svg");
    
        .folder-view-content-main {
          padding: 50px 10px 0px;
    
          .folder-view-content-header {
            color: #fff;
            font-family: "Montserrat";
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.42px;
          }
          .folder-view-content-para {
            color: #fff;
            font-family: "Inter";
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.72px;
          }
        }
      }
      .add-folder-icon-main{
        position: relative;
        .add-icon-to-folder{
            position: absolute;
             left:35%;
             top:35%;
        }
      }
      .delete-icon{
        position:absolute;
        right:0;
      }
}