.layout-main {
  display: flex;

  .header-and-content {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  .content-main {
    background: #eef0ff;
    padding: 150px 25px 25px;
    min-height: 100%;
  }
}

@media (max-width: 1200px) {
  .sidebar-content {
    position: fixed;
    background-color: white;
    z-index: 10;

    .cross-mark-main {
      position: absolute;
      right: 1em;
      top: 1em;
      cursor: pointer;
    }
  }

  .sidebar-content-hidden {
    display: none;
  }
}
