// table css start
.content {
  div.table-main {
    padding: 24px 24px 10px;
    background: #fff;
    border-radius: 8px;

    thead {
      border: 1px solid #4d5d6b;

      tr {
        th {
          color: var(--black, #131313);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid #4d5d6b;

        &:hover {
          background: #f9f9f9;
        }

        td {
          color: var(--text, #4d5d6b);
          font-family: "Montserrat";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;

          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}
// table css end
