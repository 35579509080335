.project-summary-main{
    .summary-table-main{
        background-color: white;
        padding: 20px;
        border-radius: 10px;

    }
    .table-bordered{
        border:1px solid black
    }
    .project-summary-table-head{
        background-color: '#F2F2F2';
    }
}