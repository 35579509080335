@import url("../../../assets/css/table.scss");

.plip-list-main {
  .trainer-type-dropdown-main {
    .trainer-type-dropdown {
      background: #E65C4F !important;
      padding: 10px 16px !important;
      font-family: "Montserrat" !important;
      cursor: pointer;
      border: none !important;
      display: flex !important;
      border-radius: 8px !important;
      align-items: center;
      justify-content: center;
      color: var(--white, #fff);
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 160% !important;
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 8px;
      background-color: var(--background-2, #f8f8fa);
      border: 1px solid #f8f8fa;
      color: var(--text-color, #4d5d6b);
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 0px;
    }
  }
  .plip-list-header-section {
    display: flex;
    justify-content: space-between;
    .plip-list-heading {
      font-size: 24px;
      font-weight: 600;
    }
    .search-input-main {
      background: #f8f8fa !important;
      border: 1px solid #f8f8fa !important;
      font-size: 12px !important;
      color: var(--black, #131313) !important;
      font-family: "Montserrat" !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;

      &::placeholder {
        color: var(--black, #131313) !important;
        font-family: "Montserrat" !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
    .product-list-header-flex {
      .custom-status-select {
        border-radius: 8px;
        background-color: var(--background-2, #f8f8fa);
        border: 1px solid #f8f8fa;
        color: var(--text-color, #4d5d6b);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
