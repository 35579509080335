.details-project-main {
  margin-top:20px;
 // padding: 20px 45px;
  .project-details-page-main {
    padding: 20px;
    background: #ffffff;
    border: 1px solid #4d5d6b !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .project-details-tab-main {
      display: flex;
      gap: 50px;
      align-items: center;
      .add-project-tabs {
        padding: 14px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #131313;
        cursor: pointer;
        &.active {
          color: #5046e5;
          border-bottom: 2px solid #5046e5;
        }
      }
    }
    .product-details-data-main {
      padding: 15px;
    }
  }
  .pre-heading {
    padding: 10px 20px;
    background: #ffffff;
    border-top: 1px solid #4d5d6b;
    border-left: 1px solid #4d5d6b;
    border-right: 1px solid #4d5d6b;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .add-project-card-header-main {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #131313;
  }
  .add-project-card-para-main {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #131313;
    text-align: end;
  }
  
}
p {
  font-family: Montserrat;
  margin: 0 !important;
  font-size: 14px;
  color: #4d5d6b;
}
.back-btn-function {
  cursor: pointer;
  width: 40px !important;
}
.folder-pic{
  display: flex;
  align-items: center;
  background-color:#E65C4F ;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.project-details-dropdown{
  display: flex;
  align-items: center;
  .toggle-dropdown.btn-primary{
    background-color: #E65C4F;
    padding: 10px;
  }
  .toggle-dropdown.btn-primary:hover {
    background-color: #E65C4F;
    border: 2px solid #E65C4F ;
  }
  .btn-primary{
    --bs-btn-border-color: #E65C4F;

  }
  .dropdown-toggle::after{
    border-top:none;
    
   
  }
  .dropdown-toggle::before{
    border-top:none;
   content: "";
   display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  
  
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  }
  
}


.trainer-type-dropdown-main {
  .trainer-type-dropdown {
    background: #E65C4F !important;
    padding: 10px 16px !important;
    font-family: "Montserrat" !important;
    cursor: pointer;
    border: none !important;
    display: flex !important;
    border-radius: 8px !important;
    align-items: center;
    justify-content: center;
    color: var(--white, #fff);
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 160% !important;
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    border-radius: 8px;
    background-color: var(--background-2, #f8f8fa);
    border: 1px solid #f8f8fa;
    color: var(--text-color, #4d5d6b);
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 0px;
  }
 
}
.bar-chart{
  background: white ;
  padding: 20px 45px;
  margin-top:20px;
  margin-inline: 45px;
  border: 1px solid black;
  border-radius: 10px;
  .bar-chart-heading{
 font-weight: 700;
 font-size: 24px;

  }
 
}
